import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BasicService } from '../common/services/basic.service';
import { API_VOUCHER_TRANSACTION } from '../connection.data';

@Injectable()
export class VoucherTransactionService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_VOUCHER_TRANSACTION;
    }
}